
import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/Buttons/Button.vue';
import TryAgainButton from '@/components/Buttons/TryAgainButton.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import { InventoryItem } from '@/types/Item';
import { getDropSellPrice } from '@/helpers/getDropSellPrice';
import sellItem from '@/requests/Withdraw/sellItem/sellItem';

@Component({components: {Button, TryAgainButton, LoginGeneralButton}})
export default class PrizeOptionsNew extends Vue {
  @Prop() item: InventoryItem;
  @Prop() isFarmCase: Boolean;
  blockSellRequest: boolean = false;

  goBackToOpen() {
    this.$emit('back');
  }

  openMana() {
    // TODO: handle mana modal
    // ModalModule.toggle_modal({ name: 'mana' });
  }

  toEvent() {
    this.$router.push('/event');
  }

  async sellDrop(id: number) {
    if (!this.user || this.blockSellRequest) return;

    try {
      this.blockSellRequest = true;
      const {error, user} = await sellItem(id);
      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error
        });
      }

      this.$store.commit('user/update', user);

      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Ваш выигрыш успешно продан!'
      });
      this.goBackToOpen();
    } catch (e) {
      console.error(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'warning',
        title: 'Инвентарь',
        text: 'Произошла неизвестная ошибка при продаже предмета, попробуйте позже.'
      });
    } finally {
      this.blockSellRequest = false;
    }
  }

  openRefill(code: string) {
    this.$store.dispatch('modal/toggleModal', {
      name: 'refill',
      data: {promocode: code}
    });
  }

  copyCode(code: string) {
    navigator.clipboard.writeText(code);
    this.$notify({
      group: 'notification',
      duration: 5500,
      type: 'success',
      title: `Промокод "${code}"`,
      text: 'Код скопирован в буфер обмена!'
    });
  }

  get sellPrice() {
    return getDropSellPrice(this.item.price);
  }

  get showRune() {
    return this.item?.type === 'rune' && this.isFarmCase;
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
        ? this.$store.state.user
        : null;
  }
}
