import Axios from 'axios';

import { InventoryItem } from '@/types/Item';
import { CompletedTaskNotificationResponse } from '@/requests/types';
import { MOCK_OPEN_CASE_RESPONSE } from '@/requests/Cases/openCase/mockOpenCase';
import { UserAccount } from '@/types/User';

export interface OpenCaseResponse {
    error?: string;
    user?: Partial<UserAccount>;
    winItem?: InventoryItem;
    eventCompletedTaskNotification?: CompletedTaskNotificationResponse;
}

export default async function openCase(id: number): Promise<OpenCaseResponse> {
    if (process.env.NODE_ENV === 'development') {
        if (id === 59) {
            return {
                ...MOCK_OPEN_CASE_RESPONSE,
                winItem: {
                    ...MOCK_OPEN_CASE_RESPONSE.winItem,
                    name: '20% Промокод',
                    image:
                        'https://dotaloot.me/img/cases/map_1.png',
                    price: 0,
                    market_hash_name: 'Promokod20',
                    type: 'promo'
                }
            };
        }
        return MOCK_OPEN_CASE_RESPONSE;
    }

    return (await Axios.post<OpenCaseResponse>('/api/case/open/' + id)).data;
}
